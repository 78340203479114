<template>
    <section v-if="getActiveStore.role != 'STAFF'">
        <div class="panel has-text-weight-semibold">
        <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
            <div class="columns is-mobile">
                <div class="column is-size-6 has-text-centered">UNPAID FUEL INVOICES</div>
            </div>
        </div>

        <div class="panel-block">
            <b-table class="control" 
                :data='records'
                :paginated="isPaginated"
                :per-page="perPage"
                paginationPosition="both"
                sortIcon="arrow-up"
                sortIconSize="is-small"
                default-sort=shiftDate
                defaultSortDirection='asc'
                >

            <template>
                <b-table-column field="shiftDate" label="Date" sortable sorticon  v-slot="props">
                    {{ props.row.shiftDate.substr(0,10) | formatDate }}
                </b-table-column>

                <b-table-column field="vendorName" label="Vendor" sortable sorticon  v-slot="props">
                   {{ props.row.vendorName }}
                </b-table-column>

                <b-table-column field="deptMapping" label="Type" sortable sorticon  v-slot="props">
                    {{ props.row.deptMapping }}
                </b-table-column>

                <b-table-column field="dueOn" label="Due Date" sortable sorticon  v-slot="props">
                    {{ props.row.dueOn.substr(0,10) | formatDate }}
                </b-table-column>

                <b-table-column field="fuelVolume" label="Volume" sortable sorticon  v-slot="props" :visible="showExpenseType == 2">
                    {{ props.row.fuelVolume }}
                </b-table-column>

                <b-table-column numeric field="amount" label="Amount" sortable sorticon  v-slot="props">
                    {{ props.row.amount | formatCurrency }}
                </b-table-column>

                <b-table-column field="status" label="Status" sortable sorticon  v-slot="props">
                    <span v-bind:class="[props.row.payments[0] ? '' : 'has-background-warning']">&nbsp;{{ (props.row.payments[0] ? props.row.payments[0].status  : dueOnDate(props.row.dueOn)  ) }}
                    </span>&nbsp;
                    
                    <div class="button is-info is-small" v-on:click="doAction('pay',props.row)" v-if="!props.row.payments[0]">
                        Pay
                    </div>
         
                </b-table-column>


            </template>
            
            <template slot="empty">
                <data-loading 
                    :isLoading="isLoading" 
                    :isFullPage="isFullPage"> 
                </data-loading>
            </template>

        </b-table>

        </div>
        <div class="panel-heading has-text-weight-bold">
            <div class="columns">

                <div class="column has-text-centered">
                    <div>
                    <p class="is-size-7">Total Paid Expense</p>
                    <p class="is-size-6"> {{ totalPaidExpenses | formatCurrency }}</p>
                    </div>
                </div>   

                <div class="column has-text-centered">
                    <div>
                    <p class="is-size-7">Total Unpaid Expense</p>
                    <p class="is-size-6"> {{ totalUnpaidExpenses | formatCurrency }}</p>
                    </div>
                </div>   

                <div class="column has-text-centered">
                    <div>
                    <p class="is-size-7">Total Expense</p>
                    <p class="is-size-6"> {{ totalExpenses | formatCurrency }}</p>
                    </div>
                </div>   

            </div>
        </div>
    </div>
    </section>
</template>

<script>
import UserStore from "../../../../../store/UserStore"

import DataLoading from '../../../../../components/app/DataLoading'

import axios from 'axios'

export default {

    components: {
        DataLoading,
    },

    metaInfo: {
        title: 'Fuel Payments'
    },

    data() {
        return {
            user: [],
            records: [],
            expense: {},
            expenseTemp: {},
            isPaginated: true,
            perPage: 10,
            isLoading: true,
            isFullPage: false,
            isShowDialog: false,
            action: '',
            storeId: 0,
            role: '',
            deleteAllowed: true,
            salesDateRange: [new Date(), new Date()],
            showExpenseType: "",
            showExpenseTypeId: "",
            totalExpenses: 0.00,
            totalPaidExpenses: 0.00,
            totalUnpaidExpenses: 0.00

        }
    },

    methods: {


        fetchRecords() {

             if (this.user.activeStore) {
                 var url = process.env.VUE_APP_API_ROOT_URL + '/stores/' + this.user.activeStore + "/expenses?paymentStatus=Not_Paid"
                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        this.records = response.data.data
                        // console.log(this.records)
                        this.isLoading = false
                    })
                    .catch( (err) => {
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err.response.data)
                        this.isLoading = false
                        if (err.response.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }
                    })
             }

        },

        fetchRecords1() {

            if (this.user.activeStore) {

                var startDate = this.salesDateRange[0].toISOString().split('T')[0]
                var endDate = this.salesDateRange[1].toISOString().split('T')[0]

                var url = process.env.VUE_APP_API_ROOT_URL + '/stores/' + this.user.activeStore +
                        "/expenses?" +
                        "expenseDateFrom="+startDate+"&expenseDateTo="+endDate
                if (this.showExpenseType) {
                    url = url + "&expenseTypeId="+this.showExpenseTypeId
                }
                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        this.records = response.data.data
                        // console.log(this.records)
                        this.isLoading = false
                    })
                    .catch( (err) => {
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err.response.data)
                        this.isLoading = false
                        if (err.response.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }
                    })
            }
            
        },

        toggleDialog() {
            this.isShowDialog = ! this.isShowDialog
        },
   
        getEditToolTip(delname) {
            return 'Edit ' + delname
        },

        getDeleteToolTip(delname) {
            return 'Delete ' + delname
        },

        setTotalExpenses(expenses) {
            this.totalExpenses = expenses.reduce((accum,item) => accum + parseFloat(item.amount), 0)
            this.setTotalPaidExpenses(expenses)
            this.totalUnpaidExpenses = this.totalExpenses - this.totalPaidExpenses
        },

        setTotalPaidExpenses(expenses) {
            var paidExpense = expenses.filter((item) => item.payments.length > 0)
            this.totalPaidExpenses = paidExpense.reduce((accum,item) => accum + parseFloat(item.amount), 0)
        },

        dueOnDate(dateString) {
            var dueOn = new Date(dateString)
            var today = new Date()
            if (dueOn < today) {
                return "Past Due " + (dueOn.getMonth()+1) + '/' + dueOn.getDate() + '/' + dueOn.getFullYear() 
            } else {
                return `'Due ' + (dueOn.getMonth()+1) + '/' + dueOn.getDate() + '/' + dueOn.getFullYear()`
            }
            
        },

        statusClass() {
            return "has-background-primary"
        },

        doAction(action, expense) {

            this.action = action
            this.storeId = this.user.activeStore

            switch(this.action) {
                case 'add':
                    this.toggleDialog()
                    this.expenseTemp = {
                        "date": new Date(),
                        "dueOn": new Date(),
                        "description": "",
                        "amount": "",
                        "expenseType": "",
                        "creditTerm": "",
                        "vendorId": "",
                        "userId": this.user.id,
                        "storeId": this.storeId,
                    }
                    break

                case 'edit':
                    this.toggleDialog()
                    this.expense = expense
                    this.expenseTemp = Object.assign({}, expense)
                    
                    break

                case 'pay':
                    // console.log("Delete " + expense.name)
                    this.$buefy.dialog.alert('Not Implemented yet!')
                    break
            }

        },
    },

    computed: {
        // getUser() {
        //     return UserStore.getters.getUser
        // },

        getActiveStore() {
            return UserStore.getters.getActiveStore
        },


        filteredExpenses() {
            var filteredArray = []

            if (this.showExpenseType == "") {

                filteredArray = this.records.filter(item => (item.expenseTypeId > 2));

            } else {
                filteredArray = this.records.filter(item => (item.expenseTypeId == this.showExpenseType));

            }

            this.setTotalExpenses(filteredArray)
            return filteredArray

        },

    },

    watch: {
        salesDateRange: function() {
            if (UserStore.getters.getActiveStore.role != 'STAFF') {
                this.fetchRecords()
            } else {
                this.isLoading = false
            }
        },

    },    

    mounted() {

        this.user = this.getUser()
        var today = new Date()

        if (today.getDate() == 1) {
            today.setMonth(today.getMonth() - 1)
        }
        var firstDay=new Date(today.getFullYear(), today.getMonth(), 1)
        var lastDay = new Date(today.getFullYear(), today.getMonth()+1, 0)
        this.salesDateRange=[firstDay, lastDay]        
        this.fetchRecords()

    }     

}
</script>

<style>
tr:nth-child(even) {
  background-color: #f2f2f2;
}

.btn-label-text {
    padding-left: 5px;
}
</style>